import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
// import {CookieModule} from "ngx-cookie";

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private router: Router, private _cookieService: CookieService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if (this._cookieService.get('access_token')) {
          return true;

      } else {
          this.router.navigateByUrl('/pages/login');
          return false;
      }
  }
}
