import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line
  selector: 'body',
   template: '<h1 [hidden]="true">{{title}}</h1><router-outlet></router-outlet>'
})
export class AppComponent {
    title = 'app';

  constructor() { }

}
