import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../service/authentication/authentication.service';
// import { environment } from 'environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.css'],
  providers: [AuthenticationService]
})
export class FullLayoutComponent implements OnInit {

  public disabled: boolean = false;
  public status: {isopen: boolean} = {isopen: false};
  public timeout;
  public minutesUntilLogout: number;
  public timer: number;
  public selectedLocation: string;

  constructor(private _authService: AuthenticationService) {

    this.selectedLocation = 'Germany';

    this.minutesUntilLogout = 240;
    this.timer = this.minutesUntilLogout * 60000;

    this.timeout = setTimeout(() => {
      console.log('Logged out due to inactivity.')
      this.logOut();
    }, this.timer);

  }

  public toggled(open: boolean): void {
    console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  ngOnInit(): void {}

  public logOut(): void {
       this._authService.logOut();
  }

  // Resets logout timer
  resetLogoutTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('Logged out due to inactivity.')
      this.logOut();
    }, this.timer);
  }

  /* Add new apiUrls when available.  */
  onChangeLocation(location: string) {

    switch (location) {

      case 'Germany':
          // environment.apiUrl = 'new URL';
          // 300ms: update selectedLocation after the dropdown list has vanished from the screen
          setTimeout(() => {this.selectedLocation = location; }, 300);
          break;

      case 'Database 1':
          // environment.apiUrl = 'new URL';
          setTimeout(() => {this.selectedLocation = location; }, 300);
          break;

      case 'Database 2':
          // environment.apiUrl = 'new URL';
          setTimeout(() => {this.selectedLocation = location; }, 300);
          break;

      default:
          console.log('Sidebar dropdown: default called!');
          this.selectedLocation = 'Germany';
    }
  }
}
