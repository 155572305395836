
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';



import { CookieService } from 'ngx-cookie';
import { Router} from '@angular/router';
import { environment } from 'environments/environment';

@Injectable()
export class AuthenticationService {
    // TODO put it inside config file as a constant
    endpoint: string;

  constructor(private _http: HttpClient, private _cookie: CookieService, private _router: Router) {
      this.endpoint = environment.loginApiUrl + '/login';

      // CROSS Origin not supported
      // this.endpoint = 'https://dev-api.sparkwork.io/api/login';
  }

  login(details: LoginDetails) {
      return this._http.post<any>(this.endpoint, details).pipe(
          catchError(this._errorHandler));
  }

  private  _errorHandler(error: HttpResponse<any>) {
        return observableThrowError(error || 'Some error occured on server!');

  }

  logOut(): void {
      // Remove token from cookies
       this._cookie.removeAll();
      this._router.navigateByUrl('/pages/login');


  }

  isLoggedIn(): Boolean {
      const token: string = this._cookie.get('access_token');
      return !!token;
  }

  getToken(): string {
      return this._cookie.get('access_token');
  }
}

interface LoginDetails {
    username: String;
    password: String;
}
